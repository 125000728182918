import React from 'react';

import '../css/Login.scss';

class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {};
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password).then((response) => {
      this.props.onLoginResponse(true);
    }).catch((error) => {
      this.props.onLoginResponse(false, error);
    });
  }

  render() {
    const { loginError } = this.props;
    return (
      <div id="login-page">
        <section>
          <img src="logo-big.svg" alt="Valoxia Groupe" width="345" />
          <form onSubmit={this.handleFormSubmit} method="POST">
            <div className="input-icon-line">
              <input type="text" placeholder="Entrez votre identifiant..." name="username" onChange={this.handleChange} />
              <i className="icon-login"></i>
            </div>
            <div className="input-icon-line">
              <input type="password" placeholder="...et votre mot de passe" name="password" onChange={this.handleChange} />
              <i className="icon-password"></i>
            </div>
            {loginError && (
              <div className="alert alert-danger" role="alert">
                Identifiant non valide
              </div>
            )}
            <button type="submit">SE CONNECTER</button>
          </form>
        </section>
      </div>
    )
  }
}

export default Login;