import React from "react";
import Icon from '../Icon';

export default class InviteExternal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClickDeleteEntry = this.handleClickDeleteEntry.bind(this);
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.target.value.length > 0) {
        e.target.setCustomValidity('');
        if (!e.target.checkValidity()) {
          e.target.setCustomValidity('Veuillez renseigner un adresse email.');
          e.target.reportValidity();
          return;
        }
      
        const entries = this.props.entries;
        if (entries.indexOf(e.target.value) < 0) {
          this.props.onAddEntry(e.target.value);
          e.target.value = '';
        }
      }
    }
  }

  handleClickDeleteEntry(e) {
    e.preventDefault();
    this.deleteEntry(e.target.getAttribute('data-index'));
  }

  deleteEntry(index) {
    this.props.onDeleteEntry(index);
  }

  render() {
    return (
      <div className="invite-external">
        <label htmlFor="users-external">Participants externes<br /><i>Saisir une adresse email puis appuyer sur la touche Entrée</i></label>
        <input type="email" id="users-external" name="name" onKeyDown={this.handleKeyDown}/>
        <ul>
          {this.props.entries.map((entry, index) => (
            <li key={index}>
              <button onClick={this.handleClickDeleteEntry} data-index={index} className="button round erreur" title="Retirer"><Icon icon="close" /></button>
              <input type="hidden" value={entry} name="external[]" id={`entry-${index}`} />
              <label htmlFor={`entry-${index}`}>{entry}</label>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}