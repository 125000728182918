import decode from 'jwt-decode';

class User {
    constructor(id, data, roles) {
        this.id = id;
        this.data = data;
        this.roles = roles;
        this.inMemoryJWT = null;
    }

    getId() {
        return this.id || 0;
    }

    getFirstName() {
        return this.data.firstName || '';
    }

    getLastName() {
        return this.data.lastName || '';
    }

    getFullName() {
        return `${this.getFirstName()} ${this.getLastName()}`;
    }

    getSmallName() {
        return this.getFirstName().substr(0, 1)+this.getLastName().substr(0, 1);
    }

    getRoles() {
        return this.roles;
    }

    isInterne() {
        return this.roles.indexOf('ROLE_INTERNE') >= 0;
    }

    isGuest() {
        return this.roles.indexOf('ROLE_GUEST') >= 0;
    }
    
    isCustomer() {
        return this.roles.indexOf('ROLE_CUSTOMER') >= 0;
    }

    canGlobalSearch() {
        return this.isInterne();
    }

    canCreateRoom() {
        return this.isInterne();
    }

    canInviteUse() {
        return this.isInterne();
    }
 }

const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let inMemoryUser = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = '/refresh-token';
    let refreshTimeOutId;

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const renewRefreshToken = (refreshdelay) => {
        abordRefreshToken();
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            refreshdelay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const getRefreshedToken = () => {
        if (!getLocalRefreshToken()) {
            return Promise.resolve();
        }
        const formData = new FormData();
        formData.append('refresh_token', getLocalRefreshToken());
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            body: formData,
        });
        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log(
                        'Token renewal failure'
                    );
                    return { token: null };
                }
                return response.json();
            })
            .then(({ token, refresh_token }) => {
                if (token) {
                    setToken(token, refresh_token);
                    return true;
                }
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };


    const getToken = () => inMemoryJWT;

    const getLocalRefreshToken = () => {
        return window.localStorage.getItem('refreshToken') || '';
    }

    const setToken = (token, refreshToken) => {
        inMemoryJWT = token;
        let inMemoryJWTDecoded = decode(inMemoryJWT);
        // exp - iat donne le TTL du token
        window.localStorage.setItem('refreshToken', refreshToken);
        renewRefreshToken(inMemoryJWTDecoded.exp-inMemoryJWTDecoded.iat);
        return true;
    };

    const decodeToken = (token) => {
        return decode(inMemoryJWT);
    }

    const getUser = () => {
        if (inMemoryJWT && !inMemoryUser) {
            const decoded = decodeToken(inMemoryJWT);
            inMemoryUser = new User(decoded.sub, decoded.data, decoded.roles);
        }

        return inMemoryUser;
    }

    const ereaseToken = () => {
        inMemoryJWT = null;
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    const clear = () => {
        inMemoryJWT = null;
        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem(logoutEventName);
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
        getUser,
        clear,
    }
};

export default inMemoryJWTManager();