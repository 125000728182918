import React from "react";

export default class DragAndDrop extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dragging: false
        };
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragExit = this.handleDragExit.bind(this);
    }

    handleDragEnter(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ dragging: true });
        }
    }
    handleDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();
        if (!e.relatedTarget || (!e.relatedTarget.classList.contains('drag-and-drop') && !e.relatedTarget.parentNode.classList.contains('drag-and-drop'))) {
            this.setState({ dragging: false });
        }
    }
    handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragging: false});
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.onDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    }

    handleDragExit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragging: false});
    }

    render() {
        return (
            <div className={`${this.props.className} drag-and-drop ${this.state.dragging ? 'dragging' : ''}`} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDragOver={this.handleDragOver} onDrop={this.handleDrop} onDragExit={this.handleDragExit}>
                {this.state.dragging &&
                    <div className="drag-and-drop-dragging">
                        <div>Déposez vos fichiers ici</div>
                    </div>
                }
                {this.props.children}
            </div>
        )
    }
}
