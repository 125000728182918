import React from "react";
import Icon from "../Icon";

export default class LightBox extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e)
  {
    if (!e.target.matches('.button-close') && (e.target.matches('.lightbox-overlay') || e.target.closest('.lightbox-overlay'))) {
      return;
    }
    this.close();
  }
    
  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    if (!this.props.display) {
      return null;
    }
    return (
      <div className={`lightbox${this.props.absolute ? ' absolute' : ''}`} onClick={this.handleClick}>
        <div className="lightbox-overlay">
          <button className="button round erreur button-close"><Icon icon="close" filled /></button>
          <header>
              {this.props.header}
          </header>
          <div className="lightbox-content">
            {this.props.children}
          </div>
          <footer>
            {this.props.footer}
          </footer>
        </div>
      </div>
    );
  }
}