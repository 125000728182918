import React from "react";
import SearchResultItemRoom from "./SearchResultItemRoom";
import SearchResultItemUser from './SearchResultItemUser';

export default class SearchResultList extends React.Component {
    render() {
        return (
            <div id="search-result-list">
                <div className="search-result-block">
                    <h2>Utilisateur</h2>
                    {this.props.result.users && this.props.result.users.length ? (
                        <ul>
                            {this.props.result.users.map((user) => (
                                <SearchResultItemUser key={user.id} user={user} onClick={this.props.clickOnUser} />
                            ))}
                        </ul>
                    ) : (<p>Aucun résultat.</p>)}
                </div>
                <div className="search-result-block">
                    <h2>Salons</h2>
                    {this.props.result.rooms && this.props.result.rooms.length ? (
                        <ul>
                            {this.props.result.rooms.map((room) => (
                                <SearchResultItemRoom key={room.id} room={room} onClick={this.props.clickOnRoom} />
                            ))}
                        </ul>
                    ) : (<p>Aucun résultat.</p>)}
                </div>
            </div>
        );
    }
}
