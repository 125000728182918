export default class Sound {

  static get TYPE_NEW_MESSAGE() {
    return 1;
  }

  static get TYPE_USER_JOIN() {
    return 2;
  }

  static get TYPE_USER_LEAVE() {
    return 3;
  }

  static get TYPE_TEST_SOUND() {
    return 4;
  }

  constructor(type) {
    this.type = type;
    let sound;
    if (this.type === Sound.TYPE_NEW_MESSAGE) {
      sound = 'new-message.mp3';
    } else if (this.type === Sound.TYPE_USER_JOIN) {
      sound = 'user-join.mp3';
    } else if (this.type === Sound.TYPE_USER_LEAVE) {
      sound = 'user-leave.mp3';
    } else if (this.type === Sound.TYPE_TEST_SOUND) {
      sound = 'ring.wav';
    } 
    if (sound) {
      this.audio = new Audio(`${process.env.PUBLIC_URL}/assets/sounds/${sound}`);
    }
  }

  play() {
    if (this.audio) {
      this.audio.play();
    }
  }

  static newMessage() {
    const sound = new Sound(Sound.TYPE_NEW_MESSAGE);
    sound.play();
  }

  static userJoin() {
    const sound = new Sound(Sound.TYPE_USER_JOIN);
    sound.play();
  }

  static userLeave() {
    const sound = new Sound(Sound.TYPE_USER_LEAVE);
    sound.play();
  }

  static testSound() {
    return new Promise((resolve) => {
      const sound = new Sound(Sound.TYPE_TEST_SOUND);
      sound.audio.onended = () => {
        resolve();
      };
      sound.play();
    });
  }
}