import React from 'react';
import '../css/Login.scss';

class LoginGuest extends React.Component {
  constructor(props, context){
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      loginError: false
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleFormSubmit(e){
    e.preventDefault();
    this.props.login(this.props.guestToken, null, this.state.lastname, this.state.firstname).then((response) => {
      this.props.onLoginResponse(true);
    }).catch((error) => {
      this.props.onLoginResponse(false, error);
      this.setState({loginError: true});
    });
  }

  render() {  
      const { loginError } = this.state;
      return (
        <div id="login-page">
          <section>
            <form onSubmit={this.handleFormSubmit} method="POST">
              <div className="input-icon-line">
                <input type="text" placeholder="Entrez votre nom..." name="lastname" onChange={this.handleChange} />
                <i className="icon-login"></i>
              </div>
              <div className="input-icon-line">
                <input type="text" placeholder="...et votre prénom" name="firstname" onChange={this.handleChange}/>
                <i className="icon-password"></i>
              </div>
              {loginError && (
                <div className="alert alert-danger" role="alert">
                  Une erreur est survenue
                </div>
              )}
              <button type="submit">ENTRER DANS LE SALON</button>
            </form>
          </section>
        </div>
      )
    //}
  }
}
export default LoginGuest
// connect(state => ({
//   loginError: state.app.loginError
// }))(Login);