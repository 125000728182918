import React from "react";
import Icon from './Icon';

import ButtonPickUp from './commons/ButtonPickUp';
import Api from "../tools/Api";

import ButtonDownload from "./commons/ButtonDownload";

export default class Message extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      downloadFileUrl: null,
    };
  }

  componentDidMount() {
    if (this.props.data.file && this.props.data.file.id) {
      this.fetchFile();
    }
  }

  fetchFile() {
    const api = new Api();
    api.get(`/api/file/${this.props.data.file.id}`, 'blob').then((blob) => {
      const url = window.URL.createObjectURL(blob);
      this.setState({
        downloadFileUrl: url,
      }); 
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.file) {
      if (this.props.data.file && (this.props.data.file.id !== prevProps.data.file.id || !this.state.downloadFileUrl)) {
        this.fetchFile();
      }
    }
  }


  render() {
    const data = this.props.data;
    let date = new Date(data.date);
    if (isNaN(date)) {
      date = new Date(data.date*1000);
    }
    const today = new Date();
    const isToday = date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
  
    const downloadFileUrl = this.state.downloadFileUrl || null;
    const file = this.props.data.file || null;

    let message = data.message || '';
    if (data.jitsi_room) {
      message = <div className="buttons-group">
                  <ButtonPickUp text="Rejoindre" jitsiRoomId={data.jitsi_room} onClick={(e) => {
                    this.props.onClickJoinJitsiRoom(e, data.jitsi_room, false, data.user.id)
                    }
                  }/>
                  <ButtonPickUp text="Répondre" jitsiRoomId={data.jitsi_room} onClick={(e) => {
                      this.props.onClickJoinJitsiRoom(e, data.jitsi_room, true, data.user.id)
                  }
                  } onlyAudio/>
                </div>
    } else if (file && downloadFileUrl) {
      message = <ButtonDownload url={downloadFileUrl} filename={file.name}/>
    }

    return (
    <div className={`message-item ${this.props.self ? "self" : ""}`}>
      <span className="name">{data.user.smallName.toUpperCase()}</span>
      <div className="message">
        {message} 
      </div>
      <span className="date">
        {data.isOtr === true && (
          <Icon icon="key" title="Message chiffré"/>
        )}
        {isToday ? date.toLocaleTimeString('fr-FR', {hour: "2-digit", minute: "2-digit"}) : date.toLocaleDateString('fr-FR', {year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit"})}
      </span>
    </div>
    );
  }
}
