import React, { Fragment } from 'react';
import Countdown from './../commons/Countdown';
import AppHeader from './../commons/AppHeader';

export default class GuestError extends React.Component {
    render() {
        const error = this.props.error;
        const title = error.getGlobalData().message || this.props.message;
        let date = new Date();
        var options = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", timeZone: "Europe/Paris"};
        if (error.getType() === 'validity') {
            date = new Date(error.getData().dateStart * 1000);
        }
        return (
            <main id="app-container">
                <AppHeader />
                <main id="app-main" className="guest-error">
                    <img src="images/no-visio.svg" alt="Erreur" />
                    <h1>{title}</h1>
                    {error.getType() === 'validity' && (
                        <Fragment>
                            <p>
                                
                                Votre rendez-vous est fixé pour le {date.toLocaleString('fr-FR', options)}
                            </p>
                            <p><strong>Temps restant jusqu'à l'heure de RDV :</strong></p>
                            <Countdown time={date.getTime()} />
                            <p>
                                <strong>Un problème ?</strong>
                            </p>
                            <a href="mailto:contact@valoxia.com" className="button rounded">Nous contacter</a>
                        </Fragment>
                    )}
                </main>
            </main>
        );
    }
}