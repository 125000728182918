// import AuthService from "../actions/AuthService";
//import axios from "axios";
import inMemoryJWT from '../actions/inMemoryJWT';

class Api {
  constructor(params = {}) {
    //this.params = Object.assign({}, params);
    
    this.baseUrl = process.env.REACT_APP_API_URL;
    
  }

  getHeaders() {
    return {
      'Accept': 'application/json',
      "Content-Type": "application/json",
      Authorization: "Bearer " + inMemoryJWT.getToken()
    };
  }

  get(url, responseType = 'json') {
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl+url, {
          headers: this.getHeaders(),
          method: 'GET',
          mode: 'cors', 
      }).then(response => {
        if (!response.ok) {
          throw response.statusText;
        }
        if (responseType === 'json') {
          return response.json();    
        } else if (responseType === 'blob') {
          return response.blob();
        }      
      }).then(data => {
        resolve(data);
      })
      .catch((error) => {
          reject(error)
      });
    });
  }

  post(url, body) {
    let headers = this.getHeaders();
    if (body instanceof FormData) {
      headers = {
        Authorization: "Bearer " + inMemoryJWT.getToken()
      };
    }

    return new Promise((resolve, reject) => {
      fetch(this.baseUrl+url, {
          headers: headers,
          method: 'POST',
          mode: 'cors', 
          body: body instanceof FormData ? body : JSON.stringify(body),
      }).then(response => {
        if (!response.ok) {
          throw response.statusText;
        }
        return response.json();          
      }).then(data => {
        resolve(data);
      })
      .catch((error) => {
          reject(error)
      });
    });
  }

  put(url, body) {
    let headers = this.getHeaders();
    if (body instanceof FormData) {
      headers = {
        Authorization: "Bearer " + inMemoryJWT.getToken()
      };
    }
    return new Promise((resolve, reject) => {
      fetch(this.baseUrl+url, {
          headers: headers,
          method: 'PUT',
          mode: 'cors', 
          body: body instanceof FormData ? body : JSON.stringify(body),
      }).then(response => {
        if (!response.ok) {
          throw response.statusText;
        }
        return response.json();          
      }).then(data => {
        resolve(data);
      })
      .catch((error) => {
          reject(error)
      });
    });
  }

  // patch(url, params) {
    
  // }
}

export default Api;
