import React, { Fragment } from "react";

import Api from '../../tools/Api';
import inMemoryJWT from '../../actions/inMemoryJWT';
import LightBox from "./Lightbox";
import Icon from "../Icon";
import InviteExternal from './InviteExternal';
import InviteUser from "./InviteUser";
import ButtonLoading from './ButtonLoading';
import ResultMessage from './ResultMessage';

export default class ButtonAddUser extends React.Component{  
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleAddUserEntry = this.handleAddUserEntry.bind(this);
    this.handleDeleteUserEntry = this.handleDeleteUserEntry.bind(this);
    this.handleAddExternalEntry = this.handleAddExternalEntry.bind(this);
    this.handleDeleteExternalEntry = this.handleDeleteExternalEntry.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.api = new Api();
    this.state = {
      displayLightBox: false,
      tokenLink: null,
      users: [],
      externals: [],
      submitLoading:false,
      resultMessage: '',
      loadindTitle: "Inviter",
      resultSuccess: null,
    };
  }

  handleClick(e) {
    e.preventDefault();
    const data = new FormData();
    data.append('user', inMemoryJWT.getUser().getId());

    const api = new Api();
    api.post(`/api/room/${this.props.roomId}/link`, data).then((response) => {
      this.setState({
        displayLightBox: true,
        tokenLink: response.token
      })
    });
  }

  handleCloseLightbox() {
    this.setState((state) => {
      return { displayLightBox: false };
    });
  }

  handleClickCopy(e) {
    var copyText = document.createElement('input');
    copyText.type = 'text';
    copyText.value = `${process.env.REACT_APP_URL}?link=${this.state.tokenLink}`;
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand("copy");
    copyText.remove();
  }

  handleAddUserEntry(userId) {
    const users = this.state.users || [];
    if (users.indexOf(userId) < 0) {
      users.push(userId);
    }
    this.setState({
      users
    });
  }

  handleDeleteUserEntry(userId) {
    const users = this.state.users || [];
    if (users.indexOf(userId) >= 0) {
      users.splice(users.indexOf(userId), 1);
    }
    this.setState({
      users
    });
  }

  handleAddExternalEntry(externalEntry) {
    const externals = this.state.externals;
    if (externals.indexOf(externalEntry) === -1) {
      externals.push(externalEntry);
      this.setState({
        externals
      })
    }
  }

  handleDeleteExternalEntry(index) {
    const externals = this.state.externals;
    if (externals[index]) {
      externals.splice(index, 1);
      this.setState({
        externals
      });
    }
  }

  handleSubmit() {
    if (this.canSubmit()) {
      this.setState({
        submitLoading:true,
        loadindTitle: "Chargement"
      },() => {
        this.api.put(`/api/room/${this.props.roomId}`, {
          'addUsers': this.state.users || [],
          'addExternals': this.state.externals || [],
        }).then(() => {
          this.setState({
            users:[],
            externals:[],
            loadindTitle: "Terminé",
            resultMessage: 'Invitation envoyée avec succès',
            resultSuccess: true,
          });
          setTimeout(() => {
            this.reset();
          }, 3000);
        }).catch(() => {
          this.setState({
            loadindTitle: "Erreur",
            resultMessage: 'Une erreur est survenue',
            resultSuccess: false,
          });
          setTimeout(() => {
            this.reset();
          }, 3000);
        })
      });
    }
  }

  canSubmit() {
    return (this.state.users.length > 0 || this.state.externals.length > 0) && !this.state.submitLoading;
  }


  reset() {
    this.setState({
      users: [],
      externals: [],
      submitLoading:false,
      resultMessage: '',
      loadindTitle: "Inviter",
      resultSuccess: null,
    });
  }

  render() {
    return (
      <Fragment>
        <button className="invite button round secondaire" title="Inviter un utilisateur externe" onClick={this.handleClick}>
          <Icon icon="add_user" filled/>
        </button>
        {this.state.displayLightBox && (
          <LightBox display={this.state.displayLightBox} onClose={this.handleCloseLightbox}
          header={
            <div className="lightbox-invite-user-header">
              <h2>Invitation</h2>
              <div className="lightbox-invite-user-link">
                Lien direct : {`${process.env.REACT_APP_URL}?link=${this.state.tokenLink}`}
                <button className="button round secondaire" title="Copier" onClick={this.handleClickCopy}><Icon icon="copy" filled/></button>
              </div>
            </div>
          }
          footer={
            <Fragment>
              <ResultMessage message={this.state.resultMessage} success={this.state.resultSuccess}/>
              <ButtonLoading title={this.state.loadindTitle} isDisabled={!this.canSubmit() && !this.state.resultSuccess} loadingSuccess={this.state.resultSuccess} onClick={this.handleSubmit} isLoading={this.state.submitLoading} />
            </Fragment>
          }>
            <InviteUser onAddEntry={this.handleAddUserEntry} onDeleteEntry={this.handleDeleteUserEntry} entries={this.state.users}/>
            <InviteExternal onAddEntry={this.handleAddExternalEntry} onDeleteEntry={this.handleDeleteExternalEntry} entries={this.state.externals}/>
          </LightBox>
        )}
      </Fragment>
    );
  }
}