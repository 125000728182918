import React from "react";
import Api from "../tools/Api";

export default class SearchResultItemRoom extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleClickOnRoom = this.handleClickOnRoom.bind(this);   
    }

    handleClickOnRoom() {
        const api = new Api();
    
        api
        .put(`/api/room/${this.props.room.id}/user`, {
            'hide': 0
        })
        .then((response) => {
            this.props.onClick(response);
        });
        
        // this.props.onClick(this.props.room.id);
    }
    render() {
        const room = this.props.room;
        return (
            <li key={room.id} onClick={this.handleClickOnRoom}>
                {room.name}
            </li>
        );
    }
}
