import React from "react";

import Icon from "../Icon";

export default function ButtonPickUp(props) {
  return (
    <button
      className="button rounded with-icon succes"
      onClick={props.onClick}
    >
      <Icon icon={`${!props.onlyAudio ? "camera" : "microphone"}`} filled></Icon>
      <span>{props.text}</span>
    </button>
  );
}
