import React from 'react';

export default function ResultMessage(props) {
    let classColor = 'secondaire';
    if (props.success === true) {
        classColor = 'succes';
    } else if (props.success === false) {
        classColor = 'erreur';
    }
    return (
        <p className={`result-message ${classColor}`}>{props.message}</p>
    );
}