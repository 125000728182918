import React, { useEffect, useState } from "react";

export default function Countdown(props) {

  const calculateTimeLeft = (time) => {
    const dateTo = new Date(time).getTime();
    const dateNow = new Date().getTime();
    // Find the distance between now and the count down date
    var distance = dateTo - dateNow;
    if (distance < 0) {
      window.location.reload(false);
    }
    let timeLeft = {};

    if (distance > 0) {
      timeLeft = {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props.time));

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft(props.time));
    }, 1000);
  });


  return <span className="countdown">{new Intl.NumberFormat('fr-FR', { minimumIntegerDigits: 2 }).format(timeLeft.days)}J<i></i>{new Intl.NumberFormat('fr-FR', { minimumIntegerDigits: 2 }).format(timeLeft.hours)}H<i></i>{new Intl.NumberFormat('fr-FR', { minimumIntegerDigits: 2 }).format(timeLeft.minutes)}M<i></i>{new Intl.NumberFormat('fr-FR', { minimumIntegerDigits: 2 }).format(timeLeft.seconds)}S</span>;
}