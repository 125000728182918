import React from 'react';

export default function ButtonLoading(props) {
    let classColor = 'secondaire';
    if (props.loadingSuccess === true) {
        classColor = 'succes';
    } else if (props.loadingSuccess === false) {
        classColor = 'erreur';
    }
    return (
        <button className={`button-loading button rounded ${classColor}`} disabled={props.isDisabled} onClick={props.onClick}>
            {/* {props.isLoading && (
            <svg viewBox="0 0 126 42" preserveAspectRatio="xMaxYMax slice">
                <circle r="5" id="cicle">
                    <animateMotion  dur="2s" begin="0s" fill="freeze" repeatCount="indefinite" rotate="auto" path="m 20.5,1.5 h 85 c 10.526,0 19,8.474 19,19 0,10.526 -8.474,19 -19,19 h -85 c -10.526,0 -19,-8.474 -19,-19 0,-10.526 8.474,-19 19,-19 z">
                    </animateMotion>
                </circle>
            </svg>
            )} */}
            {props.title}
        </button>
    );
}