import React, { Fragment } from "react";
import Api from '../tools/Api';

import InviteExternal from './commons/InviteExternal';
import InviteUser from "./commons/InviteUser";
import Lightbox from './commons/Lightbox';
import ButtonLoading from './commons/ButtonLoading';
import ResultMessage from './commons/ResultMessage';

export default class RoomCreate extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.api = new Api();
    this.state = {
      name: '',
      users: [],
      externals: [],
      submitLoading:false,
      resultMessage: '',
      loadindTitle: "Créer",
      resultSuccess: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleAddUserEntry = this.handleAddUserEntry.bind(this);
    this.handleDeleteUserEntry = this.handleDeleteUserEntry.bind(this);
    this.handleAddExternalEntry = this.handleAddExternalEntry.bind(this);
    this.handleDeleteExternalEntry = this.handleDeleteExternalEntry.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refInputName = React.createRef();
  }

  handleClick(e) {
    if (e.target.matches('.room-create-content') || e.target.closest('.room-create-content')) {
      return;
    }
    this.handleClose();
  }

  handleClose() {
    this.setState({
      name: '',
      users: [],
      externals: [],
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleAddUserEntry(userId) {
    const users = this.state.users || [];
    if (users.indexOf(userId) < 0) {
      users.push(userId);
    }
    this.setState({
      users
    });
  }

  handleDeleteUserEntry(userId) {
    const users = this.state.users || [];
    if (users.indexOf(userId) >= 0) {
      users.splice(users.indexOf(userId), 1);
    }
    this.setState({
      users
    });
  }

  handleAddExternalEntry(externalEntry) {
    const externals = this.state.externals;
    if (externals.indexOf(externalEntry) === -1) {
      externals.push(externalEntry);
      this.setState({
        externals
      })
    }
  }

  handleDeleteExternalEntry(index) {
    const externals = this.state.externals;
    if (externals[index]) {
      externals.splice(index, 1);
      this.setState({
        externals
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.refInputName.current.setCustomValidity('');
    if (!this.refInputName.current.checkValidity()) {
      this.refInputName.current.setCustomValidity('Veuillez renseigner nom.');
      this.refInputName.current.reportValidity();
      return;
    }
    this.createRoom();
  }

  createRoom() {
    this.setState({
      submitLoading:true,
      loadindTitle: "Chargement"
    },() => {
      this.api.post('/api/room', {
        'name': this.state.name,
        'toUsersIds': this.state.users || [],
        'externals': this.state.externals || [],
        'type': 1
      }).then((response) => {
        this.props.onRoomCreated(response);
        this.setState({
          users:[],
          externals:[],
          loadindTitle: "Terminé",
          resultMessage: 'Salon créé avec succès',
          resultSuccess: true,
        });
        setTimeout(() => {
          this.reset();
        }, 3000);
      }).catch(() => {
        this.setState({
          loadindTitle: "Erreur",
          resultMessage: 'Une erreur est survenue',
          resultSuccess: false,
        });
        setTimeout(() => {
          this.reset();
        }, 3000);
      });
    });
  }

  canSubmit() {
    return (this.state.users.length > 0 || this.state.externals.length > 0) && !this.state.submitLoading;
  }

  reset() {
    this.setState({
      users: [],
      externals: [],
      submitLoading:false,
      resultMessage: '',
      loadindTitle: "Créer",
      resultSuccess: null,
    });
  }


  render() {
    if (this.props.display) {
      return (
        <Lightbox display={true} onClose={this.handleClose} title="Invitation" 
          header={
            <h2>Créer un salon</h2>
          }
          footer={
            <Fragment>
              <ResultMessage message={this.state.resultMessage} success={this.state.resultSuccess}/>
              <ButtonLoading title={this.state.loadindTitle} isDisabled={!this.canSubmit() && !this.state.resultSuccess} loadingSuccess={this.state.resultSuccess} onClick={this.handleSubmit} isLoading={this.state.submitLoading} />
            </Fragment>
          }>
          <Fragment>
            <div>
                <label htmlFor="room-name">Nom du salon</label>
                <input type="text" id="room-name" name="name" onChange={this.handleNameChange} required ref={this.refInputName} />
            </div>
            <InviteUser onAddEntry={this.handleAddUserEntry} onDeleteEntry={this.handleDeleteUserEntry} entries={this.state.users} />
            <InviteExternal onAddEntry={this.handleAddExternalEntry} onDeleteEntry={this.handleDeleteExternalEntry} entries={this.state.externals}/>
          </Fragment>

        </Lightbox>
      )
    } else {
      return null;
    }
  }
}