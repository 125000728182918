import React from "react";
import Message from "./Message";
import { JaaSMeeting } from '@jitsi/react-sdk';

import RoomForm from "./RoomForm";
import Loader from "./commons/Loader";

import inMemoryJWT from '../actions/inMemoryJWT';
import Api from "../tools/Api";
import LightBox from "./commons/Lightbox";

import RoomHeader from "./RoomHeader";
import RoomLives from "./RoomLives";

import DragAndDrop from "./commons/DragAndDrop";

// const jsonwebtoken = require('jsonwebtoken');


// const generate = (privateKey, room, user, features, appId, kid) => {
//   const now = new Date()
//   const jwt = jsonwebtoken.sign({
//     aud: 'jitsi',
//     context: {
//       user: user,
//       features: features || null
//     },
//     iss: 'chat',
//     room: room || null,
//     sub: appId,
//     exp: Math.round(now.setHours(now.getHours() + 3) / 1000),
//     nbf: (Math.round((new Date).getTime() / 1000) - 10)
//   }, privateKey, { algorithm: 'RS256', header: { kid } })
//   return jwt;
// }


export default class Room extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.apiRef = React.createRef();

    this.state = {
      messages: [],
      actionsActive: false,
      displayMessages: true,
      displayLives: false,
      displayDocuments: false,
      kaioJitsi: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickMoreActions = this.handleClickMoreActions.bind(this);
    this.handleClickCallCamera = this.handleClickCallCamera.bind(this);
    this.handleClickCallMicrophone = this.handleClickCallMicrophone.bind(this);
    this.handleClickStartOtr = this.handleClickStartOtr.bind(this);
    this.handClickAcceptOtr = this.handClickAcceptOtr.bind(this);
    this.handleClickJoinJitsiRoom = this.handleClickJoinJitsiRoom.bind(this);
    this.handleClickHangUp = this.handleClickHangUp.bind(this);
    this.handleClickDisplayMessages = this.handleClickDisplayMessages.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.messagesContentRef = React.createRef();
    this.api = new Api();
  }


  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload.bind(this));
    this.scrollToBottom();
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload.bind(this));
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (this.messagesContentRef.current) {
      this.messagesContentRef.current.scrollTo(
        0,
        this.messagesContentRef.current.scrollHeight
      );
    }
  }
  
  onUnload(e) {
    e.preventDefault();
    e.returnValue = "";
    if (this.state.kaioJitsi) {
      this.state.kaioJitsi.disconnect();
    }
  }

  handleSubmit(message) {
    if (message.length > 0) {
      this.props.onSubmitMessage(this.props.roomId || 0, message);
    }
  }

  handleClickMoreActions(e) {
    e.preventDefault();
    const actionsActive = this.state.actionsActive;
    this.setState({
      actionsActive: !actionsActive,
    });
  }

  handleClickDisplayMessages(e) {
    e.preventDefault();
    const displayMessages = this.state.displayMessages;
    if (!displayMessages) {
      this.props.setMainLeftNavHidden(true);
    }
    this.setState({
      displayMessages: !displayMessages
    });

  }

  initKaioJitsi(jitsiRoomId, muteLocalVideo, creatorUserId) {
    let isNew = false;
    if (!jitsiRoomId) {
      isNew = true;
      jitsiRoomId =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
    }

    this.api
      .get(`/api/me/jitsiroom/${jitsiRoomId}/token`)
      .then((response) => {
        this.createJitsiInstance(jitsiRoomId, response.jwt || null, muteLocalVideo, creatorUserId);
        if (isNew === true) {
          this.props.onClickCallCamera(this.props.roomId || 0, jitsiRoomId);
        }
      }); 
  }

  createJitsiInstance(jitsiRoomId, jitsiJwt, muteLocalVideo, creatorUserId) {
    const loggedUser = inMemoryJWT.getUser();
    const isModerator = loggedUser.getId() === creatorUserId;
    const toolbarButtons = [
      'camera',
      // 'chat',
      'closedcaptions',
      'desktop',
      'download',
      // 'embedmeeting',
      'etherpad',
      'feedback',
      'filmstrip',
      'fullscreen',
      'hangup',
      'help',
      'highlight',
      //'invite',
      // 'linktosalesforce',
      //'livestreaming',
      'microphone',
      'noisesuppression',
      'participants-pane',
      'profile',
      'raisehand',
     
      'select-background',
      'settings',
      //  'shareaudio',
      //  'sharedvideo',
      'shortcuts',
      // 'stats',
      'tileview',
      'toggle-camera',
      'videoquality',
      'whiteboard',
    ];

    const interfaceConfigOverwrite = {
        //DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DEFAULT_BACKGROUND: '#3C4776',
        SHOW_POWERED_BY: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        SHOW_JITSI_WATERMARK: false,
        SETTINGS_SECTIONS: [
          'devices',
          'language',
          'sounds',
          'more',
          isModerator ? 'moderator' : null
          // 'profile', 
          // 'calendar', 
        ],
    };


    // Only if valoxia member
    if (loggedUser.isInterne()) {
      toolbarButtons.push('recording');
      toolbarButtons.push('security');
    }

    this.setState({
      displayMessages: false,
      displayLives: true,
      kaioJitsi: <JaaSMeeting
        jwt={jitsiJwt}
        appId={process.env.REACT_APP_JITSI_APP_ID}
        roomName={jitsiRoomId}
        configOverwrite={{
          startAudioOnly: muteLocalVideo,
          startWithAudioMuted: false,
          disableModeratorIndicator: true,
          startScreenSharing: false,
          enableEmailInStats: false,
          enableCalendarIntegration: false,
          readOnlyName: true,
          hideConferenceSubject: true,
          // Hides the conference timer.
          // hideConferenceTimer: false,
          toolbarButtons: toolbarButtons,
          prejoinConfig: {
            // When 'true', it shows an intermediate page before joining, where the user can configure their devices.
            // This replaces `prejoinPageEnabled`.
            enabled: true,
            // Hides the participant name editing field in the prejoin screen.
            // If requireDisplayName is also set as true, a name should still be provided through
            // either the jwt or the userInfo from the iframe api init object in order for this to have an effect.
            hideDisplayName: true,
            // List of buttons to hide from the extra join options dropdown.
             //hideExtraJoinButtons: ['no-audio', 'by-phone'],
          },
          disableAddingBackgroundImages: false,
          gravatar: {
            disabled: true,
          },
        }}
        interfaceConfigOverwrite={interfaceConfigOverwrite}
        userInfo={{
          displayName: loggedUser.getFullName(),
          role: isModerator ? 'moderator' : 'participant',
        }}
        onApiReady={(externalApi) => {
          this.apiRef.current = externalApi;
          this.apiRef.current.on('videoConferenceLeft', (params) => {
            this.disposeKaioJitsi();
          });
        }}
        onReadyToClose={() => {
          // console.log('Ready to close')
        }}
        getIFrameRef={(iframeRef) => {
          // iframeRef.style.height = '400px'; 
        }}
      />,
    });
  }

  disposeKaioJitsi() {
    this.apiRef.current.dispose();
    this.setState({
      displayMessages: true,
      displayLives: false,
      kaioJitsi: null,
    });
  }


  handleClickCallCamera(e) {
    e.preventDefault();
    if (this.state.kaioJitsi) {
      return;
    }
    this.initKaioJitsi(null, false, inMemoryJWT.getUser().getId());
    this.setState({
      actionsActive: false,
    });
  }

  handleClickCallMicrophone(e) {
    e.preventDefault();
    if (this.state.kaioJitsi) {
      return;
    }
    this.initKaioJitsi(null, true, inMemoryJWT.getUser().getId());
    this.setState({
      actionsActive: false,
    });
  }

  handleClickStartOtr() {
    this.props.onStartOtr(this.props.roomId);
    this.setState({
      actionsActive: false
    });
  }

  handClickAcceptOtr() {
    if (this.props.room.otrLightBox) {
      this.props.onAcceptOtr(this.props.roomId);
    }
  }

  handleClickHangUp(e) {
    e.preventDefault();
    this.apiRef.current.executeCommand('hangup');
    this.disposeKaioJitsi();
  }

  handleClickJoinJitsiRoom(e, jitsiRoomId, muteLocalVideo, creatorUserId) {
    e.preventDefault();
    if (this.state.kaioJitsi) {
      return;
    }
    this.initKaioJitsi(jitsiRoomId, muteLocalVideo, creatorUserId);
  }

  getTitle() {
    const room = this.props.room;
    if (this.props.roomId > 0) {
      let title;
      if (!this.isChannel()) {
        title = Object.values(room.users)
          .map((user) => {
            return `${user.firstName} ${user.lastName}`;
          })
          .join(", ");
      } else {
        title = room.name;
      }
      return title;
    } else {
      return "Général";
    }
  }

  isChannel() {
    return this.props.room && parseInt(this.props.room.type, 10) === 1;
  }

  handleFileChange(files) {
    const data = new FormData();
    data.append("room", this.props.roomId);
    data.append("file", files[0]);
    data.append("user", inMemoryJWT.getUser().getId());
    files[0].uid = new Date().getTime();
    this.props.onStartUpload(this.props.roomId, files[0]).then(() => {
      this.api.post("/api/file", data).then((response) => {
        this.props.onUploadedFile(this.props.roomId, response, files[0].uid);
      });
    });
  }

  isGeneral() {
    return parseInt(this.props.room.id) === 0;
  }


  getDisplayClass() {
    if (this.state.displayMessages && !this.state.displayLives && !this.state.displayDocuments) {
      return 'column-1';
    } else if (!this.state.displayMessages && this.state.displayLives && !this.state.displayDocuments) {
      return 'column-1';
    } else if (!this.state.displayMessages && !this.state.displayLives && this.state.displayDocuments) {
      return 'column-1';
    } else if (this.state.displayMessages && this.state.displayLives && !this.state.displayDocuments) {
      if (this.canDisplayBlockMessages()) {
        return 'column-2-messages-lives';
      } else {
        return 'column-1';
      }
    } else if (!this.state.displayMessages && this.state.displayLives && this.state.displayDocuments) {
      return 'column-2-lives-documents';
    } else if (this.state.displayMessages && !this.state.displayLives && this.state.displayDocuments) {
      return 'column-2-messages-documents';
    } else if (this.state.displayMessages && this.state.displayLives && this.state.displayDocuments) {
      return 'columns-3';
    }
  }

  canDisplayBlockMessages() {
    // Pas de videos donc messages toujours affichés
    if (!this.state.displayLives) {
      return true;
    } else {
      if (this.state.displayMessages && this.props.mainLeftNavHidden) {
        return true;
      }
    }
    return false;
  }

  render() {
    const messages = this.props.messages || [];
    const roomType = this.props.room ? this.props.room.type : null;
    const roomIsLoaded = this.props.room ? !this.props.room.isLoading : true;

    if (this.props.roomId !== this.props.activeRoom) {
      return null;
    } else {
      if (roomIsLoaded) {
        return (
          <div className={`room ${this.getDisplayClass()}`}>
            {(this.canDisplayBlockMessages()) && (
              <DragAndDrop className="room-container-messages" onDrop={(files) => this.handleFileChange(files)}>
                <RoomHeader
                  title={this.getTitle()}
                  roomId={this.props.roomId}
                  roomType={roomType}
                  room={this.props.room}
                  isGeneral={this.isGeneral()}
                  isChannel={this.isChannel()}
                  clickOnMoreAction={this.handleClickMoreActions}
                  actionsActive={this.state.actionsActive}
                  clickStartOtr={this.handleClickStartOtr}
                  clickCallMicrophone={this.handleClickCallMicrophone}
                  clickCallCamera={this.handleClickCallCamera}
                  clickOnHangUp={this.handleClickHangUp}
                  clickOnDisplayMessages={this.handleClickDisplayMessages}
                  displayMessages={this.state.displayMessages}
                  kaioJitsi={this.state.kaioJitsi}
                />
                <div className="room-body-messages" >

                  <div className="room-body-messages-content" ref={this.messagesContentRef}>
                    {messages.map((message, index) => (
                      <Message
                        key={index}
                        data={message}
                        self={parseInt(inMemoryJWT.getUser().getId(), 10) === parseInt(message.user.id, 10)}
                        onClickJoinJitsiRoom={this.handleClickJoinJitsiRoom}
                      />
                    ))}
                  </div>
                  <div className="room-form">
                    <RoomForm
                      onSubmitForm={this.handleSubmit}
                      onFileChange={this.handleFileChange}
                    />
                  </div>
                </div>
              </DragAndDrop>
            )}
            {this.state.displayLives && this.state.kaioJitsi && (
              <RoomLives
                header={
                  <RoomHeader
                    title={this.getTitle()}
                    roomId={this.props.roomId}
                    roomType={roomType}
                    room={this.props.room}
                    isGeneral={this.isGeneral()}
                    isChannel={this.isChannel()}
                    clickOnMoreAction={this.handleClickMoreActions}
                    actionsActive={this.state.actionsActive}
                    clickStartOtr={this.handleClickStartOtr}
                    clickCallMicrophone={this.handleClickCallMicrophone}
                    clickCallCamera={this.handleClickCallCamera}
                    clickOnHangUp={this.handleClickHangUp}
                    clickOnDisplayMessages={this.handleClickDisplayMessages}
                    displayMessages={this.state.displayMessages}
                    kaioJitsi={this.state.kaioJitsi}
                  />
                }
                kaioJitsi={this.state.kaioJitsi}
                enabled={this.state.displayLives && this.state.kaioJitsi}
                jitsiStats={this.state.jitsiStats || {}}
              />
            )}
            {/* 
            this.sate.displayDocuments && 
            <div className="room-container-documents">
              <!-- TODO documents -->
            </div> */}
            {this.props.room.otrLightBox && (
              <LightBox display={true} absolute={true}>
                {this.props.room.otrLightBox}
                <button onClick={() => this.props.onRefuseOtr(this.props.roomId)}>
                  Annuler
                </button>
                <button onClick={this.handClickAcceptOtr}>Accepter</button>
              </LightBox>
            )}
          </div>
        );
      } else {
        return (
          <div className="room">
            <Loader />
          </div>
        );
      }
    }
  }
}
