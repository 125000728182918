import React from "react";
import ButtonLogout from './ButtonLogout';

export default function AppHeader(props) {
  return (
    <header id="app-header">
        <img src="logo.svg" alt="Valoxia Groupe" />
        {/* TODO  masquer le bouton de deconnexion sur la page de compteur*/}
        <ButtonLogout /> 
    </header>
  );
}