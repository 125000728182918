export default class OffTheRecord {

  static generateKeyPair() {
    return new Promise((resolve) => {
      window.crypto.subtle.generateKey({name: 'ECDH', namedCurve: 'P-256'}, false, ['deriveKey']).then((cryptoKeyPair) => {
        resolve(cryptoKeyPair);
      });
    });
  }

  static exportKey(publicKey) {
    return new Promise((resolve) => {
      window.crypto.subtle.exportKey('raw', publicKey).then((key) => {
        resolve(key);
      });
    });
  }

  static generateSharedKey(cryptoKeyPair, externalPublicKey) {
    return new Promise((resolve) => {
      window.crypto.subtle.importKey('raw', externalPublicKey, {name: 'ECDH', namedCurve: 'P-256'}, false, []).then((publicKey) => {
        window.crypto.subtle.deriveKey(
          {name: 'ECDH', public: publicKey},
          cryptoKeyPair.privateKey,
          {name: 'AES-GCM', length: 256}, false, ['encrypt', 'decrypt']
        ).then((sharedKey) => {
          resolve(sharedKey);
        });
      });
    });
  }

  static encrypt(plainTextMsg, sharedKey, userName) {
    return new Promise((resolve) => {
      const textEncoder = new TextEncoder();
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      window.crypto.subtle.encrypt(
        {name: 'AES-GCM', iv, tagLength: 128, additionalData: textEncoder.encode(userName)},
        sharedKey,
        textEncoder.encode(plainTextMsg)
      ).then((encryptedMsg) => {
        resolve(OffTheRecord.concatUint8Array(iv, new Uint8Array(encryptedMsg)));
      })
    });
  }

  static decrypt(encryptedMsg, sharedKey, userName) {   
    return new Promise((resolve, reject) => {
      encryptedMsg =  new Uint8Array(encryptedMsg);
      const iv = encryptedMsg.slice(0, 12);
      const data = encryptedMsg.slice(12);
      const textDecoder = new TextDecoder();
      const textEncoder = new TextEncoder();
      window.crypto.subtle.decrypt(
        {name: 'AES-GCM', iv, tagLength: 128, additionalData: textEncoder.encode(userName)},
        sharedKey,
        data
      ).then((plainTextArrayBuffer) => {
        resolve(textDecoder.decode(plainTextArrayBuffer));
      }).catch((error) => {
        reject('Error when decrypt message.');
      })
    });
  }

  static concatUint8Array(...arrays) {
    let totalLength = 0;
    for (const arr of arrays) {
      totalLength += arr.length;
    }
    
    const result = new Uint8Array(totalLength);
    let offset = 0;
    for (const arr of arrays) {
      result.set(arr, offset);
      offset += arr.length;
    }
    return result;
  }
}