import React from "react";
import Icon from "./Icon";
// import 'emoji-mart/css/emoji-mart.css';
import pickerData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

export default class RoomForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleOnFileChange = this.handleOnFileChange.bind(this);
    this.handleClickButtonEmoji = this.handleClickButtonEmoji.bind(this);
    this.onEmojiClick = this.onEmojiClick.bind(this);
    this.textareaRef = React.createRef();
    this.state = {
      emojiPicker:false,
      canSubmit: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.sendMessage(); 
  }

  sendMessage() {
    const textarea = this.textareaRef.current;
    const message = textarea.value;
    if (message.length > 0) {
      this.props.onSubmitForm(message);
      textarea.value = "";
    }
  }

  handleKeyUp() {
    const textarea = this.textareaRef.current;
    const message = textarea.value;
    this.setState({
      canSubmit: message.length > 0
    });
  }

  handleKeyDown(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage();
    }
  }

  handleOnFileChange(e) {
    this.props.onFileChange(e.target.files);
  }

  handleClickButtonEmoji(e) {
    e.preventDefault();
    const emojiPicker = this.state.emojiPicker;
    this.setState({
      emojiPicker: !emojiPicker,
    });
  }

  onEmojiClick(emoji) {
    const textarea = this.textareaRef.current;
    const message = textarea.value;
    textarea.value = [message.slice(0, textarea.selectionStart), emoji.native, message.slice(textarea.selectionStart)].join(''); 
    textarea.focus();
    this.setState({
      emojiPicker: false,
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} method="POST">
        {this.state.emojiPicker && (
          <Picker data={pickerData} onEmojiSelect={this.onEmojiClick} native={true} />
        )}
        <button className="button round secondaire" onClick={this.handleClickButtonEmoji}>
          <Icon icon="happy" filled/>
        </button>
        <textarea
          ref={this.textareaRef}
          placeholder="Tapez votre message ici..."
          onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp}
        ></textarea>
        <div className="buttons-group room-form-actions">
          <button type="submit" className={`button round secondaire${this.state.canSubmit ? ' active' : ''}`} title="Envoyer">
            <Icon icon="send" filled />
          </button>
          <label className="button round attention upload" title="Envoyer un fichier" onChange={this.handleOnFileChange}>
            <input type="file" />
            <Icon icon="file" filled />
          </label>
        </div>
      </form>
    );
  }
}
