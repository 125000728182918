import React from "react";
import {smallName} from '../../tools/Format';
 
import { ReactComponent as SvgUsers } from './../svg/users.svg';

export default function Avatar(props) {
  let content;
  if (parseInt(props.icon, 10) !== 1) {
    content = smallName(props.name);
  } else {
    content = <SvgUsers />;
  }
  return (
    <span className="avatar">
      {content}
    </span>
  );
}