import React from "react";
import Avatar from "./commons/Avatar";
import Icon from "./Icon";
import ButtonAddUser from "./commons/ButtonAddUser";
import inMemoryJWT from '../actions/inMemoryJWT';

export default class RoomHeader extends React.Component {
    // constructor(props, context) {
    //     super(props, context);
    // }

    render() {
        return (
            <header className={`room-header${this.props.actionsActive ? ' actions-active' : ''}`}>
                <div className="room-title">
                    <Avatar name={this.props.title} icon={this.props.roomType} />
                    <div className="room-name">
                    {this.props.title}
                    {this.props.isChannel && this.props.room.users && (
                        <div className="room-name-users">
                        {this.props.room.users.map((user, index) => (                            
                            <span key={index}>{user.firstName}</span>
                        ))}
                        </div>
                    )
                    }
                    </div>
                </div>
                <div className="actions">
                    {this.props.kaioJitsi && (
                        <React.Fragment>
                        <button className="button round erreur" onClick={this.props.clickOnHangUp} title="Raccrocher">
                            <Icon icon="hang-up" filled />
                        </button>
                        <button
                            className={`split-view button round secondaire ${this.props.displayMessages === true ? " succes" : ""}`} onClick={this.props.clickOnDisplayMessages}
                            title="Messages">
                            <Icon icon="message" filled />
                        </button>
                        </React.Fragment>
                    )}
                    <button
                        className="button round secondaire more-actions"
                        title="Plus d'actions"
                        onClick={this.props.clickOnMoreAction}
                    >
                        <Icon icon="more" filled />
                    </button>
                    <div className="buttons-group vertical">
                    {!this.props.isGeneral && this.props.isChannel && inMemoryJWT.getUser().canInviteUse() && (
                        <ButtonAddUser roomId={this.props.roomId} />
                    )}
                    {!this.props.isGeneral && (
                        <button
                            className="encrypted-chat button round secondaire"
                            onClick={this.props.clickStartOtr}
                            title="Conversation chiffrée"
                        >
                            <Icon icon="key" filled/>
                        </button>
                    )}
                    <button
                        className="call-phone button round secondaire"
                        onClick={this.props.clickCallMicrophone}
                        title="Appel audio"
                    >
                        <Icon icon="phone" filled/>
                    </button>
                    <button
                        className="call-camera button round secondaire"
                        onClick={this.props.clickCallCamera}
                        title="Appel vidéo"
                    >
                        <Icon icon="camera" filled/>
                    </button>
                    </div>
                </div>
            </header>
        );
    }
}