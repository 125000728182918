import React from "react";
import Api from "../tools/Api";

export default class SearchResultItemUser extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleClickOnUser = this.handleClickOnUser.bind(this);
        
    }

    handleClickOnUser() {
        const api = new Api();
        api.post('/api/room', {
            'toUserId': this.props.user.id
        }).then((response) => {
            this.props.onClick(response);
        });
    }
    render() {
        const user = this.props.user;
        return (
            <li key={user.id} onClick={this.handleClickOnUser}>
                {user.firstName} {user.lastName}
            </li>
        );
    }
}
