import React from "react";
import RoomListItem from './RoomsListItem';
import inMemoryJWT from '../actions/inMemoryJWT';

import Icon from "./Icon";
import Api from "../tools/Api";
import SearchResultList from "./SearchResultList";

export default class RoomsList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      small: false,
      toggleNavText: 'Réduire',
      searchIsActive: false,
      searchResult: {}
    };
    this.api = new Api();
    this.handleClickRoomItem = this.handleClickRoomItem.bind(this);
    this.onClickToggleNav = this.onClickToggleNav.bind(this);
    this.handlOnKeyUpSearch = this.handlOnKeyUpSearch.bind(this);
    this.handleClickOnSearchUser = this.handleClickOnSearchUser.bind(this);
    this.handleClickOnSearchRoom = this.handleClickOnSearchRoom.bind(this);
    this.handleClickHideRoomItem = this.handleClickHideRoomItem.bind(this);
    this.inputSearch = React.createRef();
  }

  handleClickRoomItem(roomId) {
    this.props.onItemClick(roomId);
  }

  onClickToggleNav() {
    this.props.onClickToggleNav();
  }

  handlOnKeyUpSearch(e) {
    this.setState({
      searchIsActive: e.target.value.length >= 1
    });
    
    if (e.target.value.length >= 3) {
      this.api
        .get(`/api/search?search=${e.target.value}`)
        .then((response) => {
          this.setState({
            searchResult: response || {},
          });
        })
        .catch((error) => {
          this.setState({
            searchResult: {},
          });
        });
    }
  }

  resetSearch() {
    this.setState({
      searchIsActive: false
    });
    this.inputSearch.current.value = '';
  }

  handleClickOnSearchUser(room) {
    this.resetSearch();
    this.props.onRoomCreated(room);
  }

  handleClickOnSearchRoom(room) {
    this.resetSearch();
    this.props.onRoomCreated(room);
  }

  handleClickHideRoomItem(roomId) {
    this.props.onHideItem(roomId);
  }

  render() {
    const rooms = Object.values(this.props.rooms).map((room) => {
      return <RoomListItem
        key={room.id}
        room={room}
        activeRoom={this.props.activeRoom}
        onItemClick={this.handleClickRoomItem}
        unreadMessages={this.props.roomsUnreadMessages[room.id] || 0}
        clearRoomUnReadMessages={this.props.clearRoomUnReadMessages}
        clickOnHide={this.handleClickHideRoomItem}
      />
    });
    const loggedUser = inMemoryJWT.getUser();
    return (
      <div id="rooms-list" className={`${this.state.small ? 'small' : ''}`}>
        <header>
          <div className="top">
          <h2>Conversations</h2>
            <div className="actions">
              {loggedUser.canCreateRoom() && (
                <button className="button round attention" title="Créer un salon" onClick={() => this.props.onClickCreate()}><Icon icon="add" /></button>
              )}
              <button className="button round secondaire toggle" title={this.state.toggleNavText} onClick={() => this.onClickToggleNav()}><Icon icon="left" /></button>
            </div>
          </div>
          {loggedUser.canGlobalSearch() && (
          <div className="filters">
            <div className="filters-search">
              <input type="text" placeholder="Rechercher" onKeyUp={this.handlOnKeyUpSearch} ref={this.inputSearch}/>
              <Icon icon="search" />
            </div>
          </div>
           )}
        </header>
        <section>
          <div className="conversation-toggle" onClick={() => this.onClickToggleNav()}><span>Conversations</span></div>
          {!this.state.searchIsActive ? (
            <ul>
              {rooms}
            </ul>
          ) : (
            <SearchResultList result={this.state.searchResult} clickOnUser={this.handleClickOnSearchUser} clickOnRoom={this.handleClickOnSearchRoom}/>
          )}
        </section>
      </div>
    );
  }
}
