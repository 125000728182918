import React from "react";
import RoomsList from "./RoomsList";
import AppHeader from './commons/AppHeader';
import Room from "./Room";
import Api from "../tools/Api";
import RoomCreate from "./RoomCreate";
import Sound from "../tools/Sound";
import inMemoryJWT from '../actions/inMemoryJWT';
import OffTheRecord from "../actions/OffTheRecord";

export default class Home extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.api = new Api();
    this.state = {
      rooms: [],
      roomsMessages: [],
      roomsUnreadMessages: [],
      activeRoom: 0,
      componentCreateRoomActive: false,
    };
    this.handleSubmitMessage = this.handleSubmitMessage.bind(this);
    this.handleClickCallCamera = this.handleClickCallCamera.bind(this);
    this.handleClickRoomItem = this.handleClickRoomItem.bind(this);
    this.handleClickRoomHide = this.handleClickRoomHide.bind(this);
    this.handleClickCreateRoom = this.handleClickCreateRoom.bind(this);
    this.clearRoomUnReadMessages = this.clearRoomUnReadMessages.bind(this);
    this.handleCloseComponentCreateRoom = this.handleCloseComponentCreateRoom.bind(
      this
    );
    this.handleRoomCreated = this.handleRoomCreated.bind(this);
    this.onStartUpload = this.onStartUpload.bind(this);
    this.onUploadedFile = this.onUploadedFile.bind(this);
    this.onStartOtr = this.onStartOtr.bind(this);
    this.onRefuseOtr = this.onRefuseOtr.bind(this);
    this.onAcceptOtr = this.onAcceptOtr.bind(this);
    this.handleToggleNav = this.handleToggleNav.bind(this);
    this.setMainLeftNavHidden = this.setMainLeftNavHidden.bind(this);
    this.connectWsTimeout = null;

    document.title = `${
      process.env.REACT_APP_TITLE
    } - ${inMemoryJWT.getUser().getFirstName()}`;
  }
  componentDidMount() {
    this.getRooms();
  }

  connect() {
    if (this.connectWsTimeout) {
      clearTimeout(this.connectWsTimeout);
      this.connectWsTimeout = null;
    }
    
    this.websocket = new WebSocket(process.env.REACT_APP_WS_URL);
    this.websocket.onopen = () => {
      this.sendWsMessage({
        type: "user-connected",
        token: inMemoryJWT.getToken(),
      });
      console.log("ws connected");
    };

    this.websocket.onmessage = (evt) => {
      const response = JSON.parse(evt.data);
      if (response.type === "update-users") {
        const rooms = this.state.rooms;
        for (let updateUser of Object.values(response.users)) {
          for (let room of Object.values(rooms)) {
            if (room.users) {
              const roomUser = Object.values(room.users).find(user => user.id === updateUser.id);
              if (roomUser) {
                roomUser.online = true;
              }
            }
          }
        }
        this.setState({
          rooms: rooms
        });
        // TODO mettre à jour si un se déconnecte
      } else if (response.type === "new-message") {

        const rooms = this.state.rooms;        
        const messageObject = response.message;

        const roomId = messageObject.room ? messageObject.room.id : 0;
        
        if (!rooms[roomId]) {
          rooms[roomId] = messageObject.room;
          this.setState({
            rooms: rooms
          });
        }
        
        new Promise((resolve) => {
          if (response.message.isOtr) {
            const fromUser = Object.values(rooms[roomId].users).find(user => user.id === messageObject.user.id);
            OffTheRecord.decrypt(messageObject.message, fromUser.sharedKey, fromUser.firstName).then((message) => {
              resolve(message);
            }).catch((messageError)  => {
              resolve(messageError);
            });
          } else {
            resolve(messageObject.message);
          }
        }).then((message) => {
          messageObject.message = message;
          this.addRoomMessage(roomId, messageObject);
          if (!this.isActiveRoom(roomId)) {
            this.incrementRoomUnReadMessage(roomId);
            Sound.newMessage();
          }
        });
      } else if (response.type === 'start-otr') {
        const rooms = this.state.rooms;
        if (rooms[response.message.room.id]) {
          rooms[response.message.room.id].otrLightBox = `${response.message.user.firstName} souhaite démarrer une conversation chiffrée`;
          const roomUser = Object.values(rooms[response.message.room.id].users).find(user => user.id === response.message.user.id);
          roomUser.publicKey = new Uint8Array(response.message.message);
          this.setState({
            rooms: rooms
          });
          if (!this.isActiveRoom(response.message.room.id)) {
            this.incrementRoomUnReadMessage(response.message.room.id);
            Sound.newMessage();
          }
        }
      } else if (response.type === 'refuse-otr') {
        const rooms = this.state.rooms;
        if (rooms[response.message.room.id]) {
          this.addRoomMessage(response.message.room.id, response.message);
          if (!this.isActiveRoom(response.message.room.id)) {
            this.incrementRoomUnReadMessage(response.message.room.id);
            Sound.newMessage();
          }
        }
      } else if (response.type === 'accept-otr') {
        const rooms = this.state.rooms;
        if (rooms[response.message.room.id]) {
          const roomUser = Object.values(rooms[response.message.room.id].users).find(user => user.id === response.message.user.id);
          roomUser.publicKey =  new Uint8Array(response.message.message);

          // Si l'utilisateur à déjà sa clé
          if (rooms[response.message.room.id].cryptoKeyPair) {
            OffTheRecord.generateSharedKey(rooms[response.message.room.id].cryptoKeyPair, roomUser.publicKey).then((sharedKey) => {
              roomUser.sharedKey = sharedKey;
              rooms[response.message.room.id].otr = true;
              this.setState({
                rooms: rooms
              });

              const messageObject = response.message;
              messageObject.message = `${response.message.user.firstName} a accepté la conversation chiffrée`;
        
              this.addRoomMessage(response.message.room.id, messageObject);
              if (!this.isActiveRoom(response.message.room.id)) {
                this.incrementRoomUnReadMessage(response.message.room.id);
                Sound.newMessage();
              }
            });
          } else {
            this.setState({
              rooms: rooms
            });
          }          
        }
      }
    };

    this.websocket.onclose = () => {
      this.sendWsMessage({
        type: "user-disconnected",
        token: inMemoryJWT.getToken(),
      });

      if (process.env.NODE_ENV !== 'development') {
        this.connectWsTimeout = setTimeout(() => {
          this.connect();
        }, 5000);
      }
      console.log("ws disconnected");
    };
  }

  sendWsMessage(message) {
    this.websocket.send(JSON.stringify(message));
  }

  getRoom(roomId) {
    return this.state.rooms[roomId] || {};
  }

  getRooms() {
    this.api
      .get(`/api/rooms`)
      .then((response) => {
        const rooms = {};
        [...response].forEach((room) =>  {
          room.isReady = false; // Permet de savoir si le salon a déjà été chargé, ex chargement des messages
          room.isLoading = false;
          rooms[room.id] = room;
        });
        this.setState(
          {
            rooms: rooms || {},
          },
          () => {
            this.connect();
            const urlParams = new URLSearchParams(window.location.search);
            if (parseInt(urlParams.get('room'), 10) > 0) {
              this.handleClickRoomItem(parseInt(urlParams.get('room'), 10));
            } else {
              if ([...response].length === 1) {
                this.handleClickRoomItem([...response].shift().id);
              } else {
                this.handleClickRoomItem(0);
              }
            }
          }
        );
      })
      .catch((error) => {
        this.setState({
          rooms: [],
        });
      });
  }

  incrementRoomUnReadMessage(room) {
    const roomsUnreadMessages = this.state.roomsUnreadMessages;
    if (!roomsUnreadMessages[room]) {
      roomsUnreadMessages[room] = 0;
    }
    roomsUnreadMessages[room]++;
    this.setState({ 
      roomsUnreadMessages: roomsUnreadMessages
    });
  }

  clearRoomUnReadMessages(room) {
    const roomsUnreadMessages = this.state.roomsUnreadMessages;
    if (roomsUnreadMessages[room]) {
      roomsUnreadMessages[room] = 0;
      this.setState({
        roomsUnreadMessages: roomsUnreadMessages
      });
    }
  }

  addRoomMessage(room, messageData, roomMessageIndex) {
    return new Promise((resolve) => {
      const roomsMessages = this.state.roomsMessages;
      if (!roomsMessages[room]) {
        roomsMessages[room] = [];
      }
      if (roomMessageIndex === undefined || roomMessageIndex < 0) {
        roomsMessages[room].push(messageData);
      } else {
        roomsMessages[room][roomMessageIndex] = messageData;
      }
      this.setState({
        roomsMessages: roomsMessages
      }, () => {
        resolve();
      });
    });
  }

  handleSubmitMessage(roomId, message) {
    const rooms = this.state.rooms;
    
    this.addRoomMessage(roomId, {
      message: message,
      user: {
        id: inMemoryJWT.getUser().getId(),
        firstName: inMemoryJWT.getUser().getFirstName(),
        lastName: inMemoryJWT.getUser().getLastName(),
        smallName: inMemoryJWT.getUser().getSmallName()
      },
      date: Date.now(),
      isOtr: rooms[roomId].otr === true
    });
    if (rooms[roomId].otr === true) {
      // On envoie un message chiffré pour chaque utilisateur
      for (let roomUser of Object.values(rooms[roomId].users)) {
        if (!roomUser.sharedKey) {
          continue;
        }
        OffTheRecord.encrypt(message, roomUser.sharedKey, inMemoryJWT.getUser().getFirstName()).then((encryptedMsg) => {
          this.sendWsMessage({
            room: roomId,
            type: "send-message",
            message: encryptedMsg,
            otr: true,
            toUser: roomUser.id
          });  
        });
      }
    }  else {
      this.sendWsMessage({
        room: roomId,
        type: "send-message",
        message: message,
      });
    }
  }

  handleClickCallCamera(room, jitsiRoomId) {
    this.addRoomMessage(room, {
      jitsi_room: jitsiRoomId,
      user: {
        id: inMemoryJWT.getUser().getId(),
        firstName: inMemoryJWT.getUser().getFirstName(),
        lastName: inMemoryJWT.getUser().getLastName(),
        smallName: inMemoryJWT.getUser().getSmallName()
      },
      date: Date.now(),
    });

    this.sendWsMessage({
      room: room,
      type: "send-message",
      jitsiRoom: jitsiRoomId,
    });
  }

  handleClickRoomItem(roomId) {
    const rooms = this.state.rooms;
    const room = rooms[roomId];
    if (room && !room.isReady) {
      room.isReady = true;
      room.isLoading = true;
      rooms[roomId] = room;
      this.setState({rooms: rooms});
      this.api
      .get(`/api/room/${room.id}/messages`)
      .then((response) => {

        const roomsMessages = this.state.roomsMessages;
        if (!roomsMessages[room.id]) {
          roomsMessages[room.id] = [];
        }
    
        roomsMessages[room.id] = response;

        room.isLoading = false;
        rooms[roomId] = room;
        this.setState({
          rooms: rooms,
          roomsMessages: roomsMessages,
        });
      })
      .catch((error) => {
        
      });
    }
    this.setActiveRoom(roomId);
  }

  handleClickRoomHide(roomId) {
    const rooms = this.state.rooms;
    const room = rooms[roomId];
    if (room) {
      this.api
      .put(`/api/room/${room.id}/user`, {
        'hide': 1
      })
      .then((response) => {
        delete rooms[roomId];
        this.setState({
          rooms: rooms
        });
      });
    }
  }

  setActiveRoom(roomId) {
    this.setState({ 
      activeRoom: roomId
    });
  }

  isActiveRoom(roomId) {
    return this.state.activeRoom === roomId;
  }

  handleClickCreateRoom() {
    const componentCreateRoomActive = this.state.componentCreateRoomActive;
    this.setState({ 
      componentCreateRoomActive: !componentCreateRoomActive
    });
  }

  handleCloseComponentCreateRoom() {
    this.setState({ 
      componentCreateRoomActive: false
    });
  }

  handleRoomCreated(room) {
    const rooms = this.state.rooms;
    
    if (!rooms[room.id]) {
      rooms[room.id] = room;
      this.setState({
        rooms: rooms,
      }, () => this.handleClickRoomItem(room.id));
    } else {
      this.setActiveRoom(room.id);
    }
  }

  onStartUpload(roomId, file) {
    return new Promise(async (resolve) => {
      const message = `Chargement fichier : ${file.name}`;
      // this.state.roomsMessages[roomId].find((message) => message.fileUid === file)
      await this.addRoomMessage(roomId, {
        message: message,
        user: {
          id: inMemoryJWT.getUser().getId(),
          firstName: inMemoryJWT.getUser().getFirstName(),
          lastName: inMemoryJWT.getUser().getLastName(),
          smallName: inMemoryJWT.getUser().getSmallName()
        },
        date: Date.now(),
        file: file 
      });
      resolve();
    });
  }

  onUploadedFile(roomId, file, fileUid) {
    let roomMessage = this.state.roomsMessages[roomId].find((data) => {
      return data.file && data.file.uid === fileUid
    });
    const roomMessageIndex = this.state.roomsMessages[roomId].indexOf(roomMessage);
    const message = `Nouveau fichier : ${file.name}` 
    if (roomMessageIndex < 0) {
      roomMessage = {
        user: {
          id: inMemoryJWT.getUser().getId(),
          firstName: inMemoryJWT.getUser().getFirstName(),
          lastName: inMemoryJWT.getUser().getLastName(),
          smallName: inMemoryJWT.getUser().getSmallName()
        },
        date: Date.now(),
      };
    }
    roomMessage.message = message;
    roomMessage.file = file;
    
    this.addRoomMessage(roomId, roomMessage, roomMessageIndex);

    this.sendWsMessage({
      room: roomId,
      type: "send-message",
      message: message,
      file: file
    });
  }

  onStartOtr(roomId) {
    const rooms = this.state.rooms;
    if (rooms[roomId]) {
      OffTheRecord.generateKeyPair().then((cryptoKeyPair) => {
        rooms[roomId].cryptoKeyPair = cryptoKeyPair;
        OffTheRecord.exportKey(cryptoKeyPair.publicKey).then((key) => {
          rooms[roomId].publicKey = new Uint8Array(key);
          this.sendWsMessage({
            room: roomId,
            type: 'start-otr',
            publicKey: rooms[roomId].publicKey
          });
          this.setState({
            rooms: rooms
          })
        });
      });
    }
  }

  onRefuseOtr(roomId) {
    this.sendWsMessage({
      room: roomId,
      type: 'refuse-otr',
    });
    const rooms = this.state.rooms;
    if (rooms[roomId]) {
      rooms[roomId].otrLightBox = null;
      this.setState({
        rooms: rooms
      });
    }
  }

  onAcceptOtr(roomId) {
    const rooms = this.state.rooms;
    if (rooms[roomId]) {
      OffTheRecord.generateKeyPair().then((cryptoKeyPair) => {
        rooms[roomId].cryptoKeyPair = cryptoKeyPair;
        OffTheRecord.exportKey(cryptoKeyPair.publicKey).then((key) => {
          rooms[roomId].publicKey = new Uint8Array(key);
          rooms[roomId].otr = true;
          this.sendWsMessage({
            room: roomId,
            type: 'accept-otr',
            publicKey: rooms[roomId].publicKey
          });

          const promises = [];
          for (let roomUser of Object.values(rooms[roomId].users)) {
            if (roomUser.publicKey) {
              promises.push(OffTheRecord.generateSharedKey(rooms[roomId].cryptoKeyPair, roomUser.publicKey).then((sharedKey) => {
                roomUser.sharedKey = sharedKey;
              }));
            }
          }
          Promise.all(promises).then(() => {
            rooms[roomId].otrLightBox = null;
            this.setState({
              rooms: rooms
            });
          });          
        });
      });
    }    
  }
  
  handleToggleNav() {
    this.setMainLeftNavHidden(!this.state.mainLeftNavHidden);
  }

  setMainLeftNavHidden(hidden) {
    this.setState({
      mainLeftNavHidden: hidden
    });
  }

  render() {
    return (
      <div id="app-container">
        <AppHeader />
        <div id="home" className={`${this.state.mainLeftNavHidden ? 'room-list-small' : ''}`}>
          <RoomsList
            users={this.state.users}
            rooms={this.state.rooms}
            onItemClick={this.handleClickRoomItem}
            activeRoom={this.state.activeRoom}
            onClickCreate={this.handleClickCreateRoom}
            roomsUnreadMessages={this.state.roomsUnreadMessages}
            clearRoomUnReadMessages={this.clearRoomUnReadMessages}
            onClickToggleNav={this.handleToggleNav}
            onRoomCreated={this.handleRoomCreated}
            onHideItem={this.handleClickRoomHide}
          />
          <div id="room-container">
            {Object.values(this.state.rooms).map((room, index) => (
              <Room
                key={room.id}
                roomId={room.id}
                room={room}
                fromId={inMemoryJWT.getUser().getId()}
                messages={this.state.roomsMessages[room.id] || []}
                onSubmitMessage={this.handleSubmitMessage}
                onClickCallCamera={this.handleClickCallCamera}
                activeRoom={this.state.activeRoom}
                onStartUpload={this.onStartUpload}
                onUploadedFile={this.onUploadedFile}
                onStartOtr={this.onStartOtr}
                onRefuseOtr={this.onRefuseOtr}
                onAcceptOtr={this.onAcceptOtr}
                mainLeftNavHidden={this.state.mainLeftNavHidden}
                setMainLeftNavHidden={this.setMainLeftNavHidden}
              ></Room>
            ))}
          </div>
          {inMemoryJWT.getUser().canCreateRoom() && (
          <RoomCreate
            onRoomCreated={this.handleRoomCreated}
            display={this.state.componentCreateRoomActive}
            onClose={this.handleCloseComponentCreateRoom}
          />
          )}
        </div>
      </div>
    );
  }
}
