import React from "react";
import Api from "../../tools/Api";

export default class InviteUser extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.api = new Api();
    this.state = {
        users: []
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.api
      .get(`/api/users`)
      .then((response) => {
        this.setState({
            users: response || [],
        });
      })
      .catch((error) => {
        this.setState({
          users: [],
        });
      });
  }

  handleChange(e) {
    if (e.target.checked === true) {
        this.props.onAddEntry(e.target.value);
    } else {
        this.props.onDeleteEntry(e.target.value);
    }
  }

  render() {
    return (
    <div className="invite-users">
        <label htmlFor="users">Participants</label>
        <ul>
          {this.state.users.map((user) => (
            <li key={`user-${user.id}`}>
              <input type="checkbox" value={user.id} name="users[]" id={`user-${user.id}`} onChange={this.handleChange} />
              <label htmlFor={`user-${user.id}`}>{user.firstName} {user.lastName}</label>
            </li>
          ))}
        </ul>
    </div>
    )
  }
}