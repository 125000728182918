import React from "react";

import Icon from "./../Icon";

export default function ButtonDownload(props) {
  return (
    <a
      href={props.url}
      download={props.filename}
      className="button rounded secondaire with-icon download"
    >
      <Icon icon="file" filled></Icon>
      <span>{props.filename}</span>
    </a>
  );
}
