import React from "react";
const url = 'assets/symbol-defs.svg';

const Icon = (props) => (
  <span className={`icon-item${props.filled ? ' filled' : ''}`} title={`${props.title || ''}`}>
    <svg viewBox='0 0 16 16' className={`icon icon-${props.icon}`}>
      <use xlinkHref={`${url}#icon-${props.icon}`} />
    </svg>
  </span>
);

export default Icon;