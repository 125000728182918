import React from "react";
import Avatar from "./commons/Avatar";
import inMemoryJWT from '../actions/inMemoryJWT';
import Icon from "./Icon";

export default class RoomsListItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      'mouseEnter': false
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleClickHide = this.handleClickHide.bind(this);
  }

  isActiveRoom() {
    return this.props.activeRoom === this.props.room.id;
  }

  handleMouseEnter() {
    this.setState({
      mouseEnter: true
    });
  }

  handleMouseLeave() {
    this.setState({
      mouseEnter: false
    });
  }

  handleClickHide() {
    this.props.clickOnHide(this.props.room.id);
  }

  render() {
    const room = this.props.room;
    const directRoom = room.type !== 1;
    let roomName = room.name;
    let online = false;
    if (!roomName && directRoom) {
      const user = Object.values(room.users).find(user => parseInt(user.id) !== parseInt(inMemoryJWT.getUser().getId()));
      if (user) {
        roomName = `${user.firstName} ${user.lastName}`;
        online = user.online || false;
      }
    }

    if (this.isActiveRoom() && !this.timer) {
      this.timer = setTimeout(() => this.props.clearRoomUnReadMessages(room.id), 3000);
    } else if (!this.isActiveRoom() && this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    return (
      <li
        className={`room-nav${
          this.isActiveRoom() ? " active" : ""
          }${
          online ? ' online' : ''
          }`}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="room-nav-content" onClick={() => this.props.onItemClick(room.id)}>
          <div className="room-nav-name">
            <Avatar name={roomName} icon={room.type} />
            {this.props.unreadMessages > 0 && (
              <span className="unread-messages">{this.props.unreadMessages}</span>
            )}
            {roomName}
          </div>
        </div>
          {inMemoryJWT.getUser().isInterne() && room.id > 0 && this.state.mouseEnter && (
              <button className="button-hide button round erreur" onClick={this.handleClickHide}>
                <Icon icon="close" />
              </button>
          )}
        {/* <div className="room-nav-actions">
          <button className="button round more-actions">
            <Icon icon="more" />
          </button>
          <div className="actions">
            <ul>
              <li><button>Masquer</button></li>
            </ul>
          </div>
        </div> */}
      </li>
    );
  }
}
