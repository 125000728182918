
import inMemoryJWT from './inMemoryJWT';
import GuestLoginError from '../errors/GuestLoginError';

const authProvider = {
    login: (username, password) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/api/login_check`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({ 'Accept': 'application/json', 'Content-Type': 'application/json' }),
            body: JSON.stringify({
                username,
                password
            }),
            // credentials: 'include',
        });
        inMemoryJWT.setRefreshTokenEndpoint(`${process.env.REACT_APP_API_URL}/api/token/refresh`);
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, refresh_token}) => {
                authProvider.loggedIn = true;
                return inMemoryJWT.setToken(token, refresh_token);
            });
    },


    loginGuest: (guestToken, guestKey, lastName, firstName) => {
        const formData = new FormData();
        formData.append('token', guestToken);
        formData.append('key', guestKey || null);
        formData.append('lastName', lastName || '');
        formData.append('firstName', firstName || '');
        const request = new Request(`${process.env.REACT_APP_API_URL}/api/guest_login`, {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({ 'Accept': 'application/json' }),
            body: formData,
            // credentials: 'include',
        });
        inMemoryJWT.setRefreshTokenEndpoint(`${process.env.REACT_APP_API_URL}/api/token/refresh`);
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    return response.json().then((data) => {
                        const json = {
                            'data': data,
                            'message': response.statusText,
                        };
                        throw new GuestLoginError(JSON.stringify(json));
                        
                    });
                    // throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, refresh_token}) => {
                inMemoryJWT.setToken(token, refresh_token);
                return true;
            });
    },
    
    logout: () => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/api/logout`, { // FIXME attention ca deconnnecte partout, supprime tous les refreshtoken de l'username
            method: 'GET',
            headers: new Headers({ 
                'Content-Type': 'application/json',
                Authorization: "Bearer " + inMemoryJWT.getToken()
            }),
            // credentials: 'include',
        });
        inMemoryJWT.ereaseToken();
        inMemoryJWT.clear();
        return fetch(request).then(() => true);
    },

    checkAuth: () => {
        if (!inMemoryJWT.getToken()) {
            inMemoryJWT.setRefreshTokenEndpoint(`${process.env.REACT_APP_API_URL}/api/token/refresh`);
            return inMemoryJWT.getRefreshedToken().then(tokenHasBeenRefreshed => {
                if  (tokenHasBeenRefreshed) {
                    return Promise.resolve();
                }  else {
                    return Promise.reject();
                } 
            });
        } else {
            return Promise.resolve();
        }
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        if (!inMemoryJWT.getToken()) {
            inMemoryJWT.setRefreshTokenEndpoint(`${process.env.REACT_APP_API_URL}/api/token/refresh`);
            return inMemoryJWT.getRefreshedToken().then(tokenHasBeenRefreshed => {
                return tokenHasBeenRefreshed ? Promise.resolve() : Promise.reject();
            });
        } else {
            return Promise.resolve();
        }
    },
};

export default authProvider; 