import React from "react";
import {AuthContext} from './context/AuthContext';
import Home from './components/Home';
import Login from './components/Login';
import Loader from './components/commons/Loader';
import LoginGuest from './components/LoginGuest';
import GuestError from './components/errors/GuestError';

import "./css/global/index.scss";


class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      display: <Loader />,
      loggedIn: false,
      loaded: false,
      roomLinkToken: null,
      roomLinkKey:null
    };
  }
  static contextType = AuthContext;
  setLoggedIn(loggedIn) {
    this.setState({
      loggedIn: loggedIn
    });
  }

  setLoaded(loaded) {
    this.setState({
      loaded: loaded
    });
  }

  setRoomLinkToken(roomLinkToken) {
    this.setState({
      roomLinkToken: roomLinkToken
    });
  }

  setRoomLinkKey(roomLinkKey) {
    this.setState({
      roomLinkKey: roomLinkKey
    });
  }

  componentDidMount() {
    this.context.checkAuth().then(() => {
      this.setLoggedIn(true);
      this.setLoaded(true);
      this.setState({
        display: <Home />
      });
    }).catch(() => {
      this.setLoggedIn(false);
      const urlParams = new URLSearchParams(window.location.search);
      this.setRoomLinkToken(urlParams.get('link'));
      this.setRoomLinkKey(urlParams.get('key'));
      if (urlParams.get('link'))  {
        // Auto login guest
          if (urlParams.get('key')) {
            this.context.loginGuest(urlParams.get('link'), urlParams.get('key')).then((response) => {
              this.setState({
                display: <Home />
              })
            }).catch((error) => {
              this.setState({
                display: <GuestError error={error}/>
              })
            });
          // Invitation avec création de compte
          } else {
            this.setState({
              display: <LoginGuest guestToken={urlParams.get('link')} login={this.context.loginGuest} onLoginResponse={(loggedIn) => {
                this.setLoggedIn(loggedIn);
                if (loggedIn) {
                  this.setState({
                    display: <Home />
                  });
                }
              }}/>
            })
          }
      } else {
        this.setState({
          display: <Login login={this.context.login} onLoginResponse={(loggedIn) => {
            this.setLoggedIn(loggedIn)
            if (loggedIn === true) {
              this.setState({
                display: <Home />
              });
            }
          }}/>
        })
        this.setLoaded(true);
      }
    })
  }

  render() {
    return this.state.display;
  }
}

export default App;
