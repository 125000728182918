export default class GuestLoginError extends Error {
    constructor(message) {
        const decode = JSON.parse(message);
        super(decode.message || 'An error as occured');
        this.data = decode.data || {};
    }

    getGlobalData() {
        return this.data;   
    }
    getData() {
        return this.getGlobalData().data || {};
    }

    getType() {
        return this.data.data.type || null;
    }
}