import React from "react";

export default function RoomLives(props) {
    return (
        <div className="room-container-lives">
            {props.header}
            <div className={`room-body-videos`} >
                <div className="room-body-videos-content" >
                    <div className="room-body-videos-content-inner">
                        <div className="room-body-videos-wrapper">
                            {props.kaioJitsi}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
