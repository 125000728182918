import React from 'react';
import {AuthContext} from './../../context/AuthContext';

export default class ButtonLogout extends React.Component{
    constructor(props, context) {
        super(props, context);
        this.handleOnClick = this.handleOnClick.bind(this);
    }
    static contextType = AuthContext;

    handleOnClick = () => {
        this.context.logout().then(() => {
            window.location.href = window.location.protocol+'//'+window.location.host
        });
    }

    render() {
        return (
            <button className={`button rounded erreur`} onClick={this.handleOnClick}>
                Déconnexion
            </button>
        );
    }        
}